@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.fontstyle-lato {
  font-family: "Lato";
}

.circle {
  position: relative;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d0e7e9;
}
.logo {
  /* position: absolute; */
  width: 120px;
  height: 120px;
  margin-left: 15px;
  margin-bottom: 15px;
  /* background: #000; */
  /* border-radius: 50%; */
}
.text {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotateText 10s linear infinite;
}

@keyframes rotateText {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text span {
  position: absolute;
  left: 50%;
  font-size: 1rem;
  transform-origin: 0 120px;
  text-transform: uppercase;
}
.bg-text {
  font-size: 80px;
}
.position1 {
  top: 36px;
}
.position2 {
  top: 128px;
}

@media screen and (max-width: 340px) {
  .circle {
    width: 180px;
    height: 180px;
  }
  .text span {
    transform-origin: 0 90px;
  }
  .logo {
    width: 90px;
    height: 90px;
  }
  .bg-text {
    font-size: 55px;
  }
  .position1 {
    top: 30px;
  }
  .position2 {
    top: 100px;
  }
}
@media screen and (max-width: 268px) {
  .circle {
    width: 120px;
    height: 120px;
  }
  .text span {
    transform-origin: 0 60px;
    font-size: 0.5rem;
  }
  .logo {
    width: 60px;
    height: 60px;
  }
  .bg-text {
    font-size: 32px;
  }
  .position1 {
    top: 30px;
  }
  .position2 {
    top: 70px;
  }
}

.transition-custom-bg {
  transition: all 0.7s;
  -webkit-transition: all 0.7s;
}
.transition-custom-sidebar {
  transition: all 1s;
  -webkit-transition: all 1s;
}
.text-area {
  resize: none;
  background-image: -webkit-linear-gradient(left, #1b3654 0, transparent 0),
    -webkit-linear-gradient(right, #1b3654 0, transparent 0),
    -webkit-linear-gradient(#1b3654 30px, transparent 31px, transparent 30px, #1b3654
          31px);
  background-repeat: repeat-y;
  background-size: 100% 100%, 100% 100%, 100% 31px;
  background-attachment: local;
  line-height: 31px;
  overflow-y: scroll;
  outline: none;
  border-style: none;
  border-color: transparent;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.text-area::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.text-area {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

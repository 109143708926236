@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.fontstyle-lato {
  font-family: "Lato";
}

@media screen and (max-width: 270px) {
  .break-all-custom {
    word-break: break-all;
  }
}
.disp-rocket2 {
  display: none;
}
.disp-rocket1 {
  display: none;
}
@media only screen and (min-width: 451px) and (max-width: 640px) {
  .disp-rocket1 {
    display: block;
  }
}
@media only screen and (min-width: 298px) and (max-width: 450px) {
  .disp-rocket2 {
    display: block;
  }
}

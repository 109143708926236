@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.fontstyle-lato {
  font-family: "Lato";
}

.transition-custom {
  transition: all 1s;
  -webkit-transition: all 1s;
}
@media screen and (max-width: 410px) {
  .text-resp {
    padding: 0 20px;
  }

  .blob-resp {
    overflow: hidden;
    width: 100px;
    height: 400px;
    right: -20px;
  }
}
@media screen and (max-width: 240px) {
  .blob-resp {
    display: none;
  }
}
